import React from "react"
import { ProjectLayout } from "../layouts"

export default () => (
  <ProjectLayout
    title="Wanderolla"
    teaser="Plan trips, discover new places with personalized travel guides"
    note="Tech stack: React, Django"
    year="2016 - 2018"
  >
    <blockquote class="twitter-tweet">
      <p lang="ru" dir="ltr">
        В продолжение треда про мои головокружительные (нет) успехи (пфф),
        расскажу, как в Тбилиси писал код, учился правильно выговаривать с
        придыханием хачапури [к’хаачапури] и пить цицку.{" "}
        <a href="https://t.co/X45r5Ym7CA">https://t.co/X45r5Ym7CA</a>
      </p>
      &mdash; Kirill (@moonk){" "}
      <a href="https://twitter.com/moonk/status/1300028783202893825?ref_src=twsrc%5Etfw">
        August 30, 2020
      </a>
    </blockquote>
    <img src="images/projects/wanderolla/wanderolla_search.jpg" />
    <img src="images/projects/wanderolla/wanderolla_trip.jpg" />
    <img src="images/projects/wanderolla/wanderolla_card.jpg" />
    <img src="images/projects/wanderolla/wanderolla_create.jpg" />
  </ProjectLayout>
)
